import React from "react";
import "./chat.scss";
import useScrollbarWidth from "../../hooks/use-scrollbar-width";

export default function Chat({ url }) {
  const scrollbarWidth = useScrollbarWidth();

  if (!url) {
    return null;
  }
  return (
    <div className="Chat">
      <iframe src={url} style={{ width: `calc(100% + ${scrollbarWidth}px` }} />
    </div>
  );
}
