import React, {useCallback, useContext, useEffect, useRef} from "react";
import clamp from "lodash/clamp";
import "./direct-chat.scss";
import {CampaignContext} from "../../config/context";
import api from "../../services/api";

export default function DirectChat({ imageUrl, url }) {
  const openWindow = useRef(null);
  const campaign = useContext(CampaignContext);

  const openChat = useCallback(() => {
    api.campaignChat(campaign.id);
    const width = clamp(window.innerWidth * 0.5, 300, 400);
    const height = clamp(window.innerHeight * 0.75, 300, 600);
    const left = screen.availLeft + (screen.availWidth - width);
    const top = screen.availTop + (screen.availHeight - height);
    openWindow.current = window.open(
      url,
      "_blank",
      `left=${left},top=${top},width=${width},height=${height}`
    );
  }, [url, openWindow]);

  const closeChat = useCallback(() => {
    if (openWindow.current) {
      openWindow.current.close();
    }
  }, [openWindow]);

  useEffect(() => {
    window.addEventListener("beforeunload", closeChat);
    window.removeEventListener("beforeunload", closeChat);
    return closeChat;
  }, []);

  if (!imageUrl) {
    return null;
  }
  return (
    <div className="DirectChat" onClick={openChat}>
      <img src={imageUrl} alt="" />
    </div>
  );
}
