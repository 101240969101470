import React, { useContext } from "react";
import "./header.scss";
import { CampaignContext } from "../../config/context";
import useDisableDrag from "../../hooks/use-disable-drag";

export default function Header() {
  const campaign = useContext(CampaignContext);
  const disableDrag = useDisableDrag();

  return (
    <div className="Header">
      {campaign.titleImageUrl && (
        <div className="Header__title">
          <img src={campaign.titleImageUrl} alt="" onDragStart={disableDrag} />
        </div>
      )}
    </div>
  );
}
