import React, { useContext } from "react";
import "./stream.scss";
import { CampaignContext } from "../../config/context";
import Video from "../video/video";
import Chat from "../chat/chat";
import DirectChat from "../direct-chat/direct-chat";
import Advert from "../advert/advert";
import useDisableDrag from "../../hooks/use-disable-drag";

export default function Stream() {
  const {
    id,
    videoUrl,
    chatUrl,
    ctaUrl,
    state,
    directChatUrl,
    preEventImageUrl,
    postEventImageUrl,
    chatButtonImageUrl,
    ctaButtonImageUrl,
    advertUrl,
    advertImageUrl,
    facebook,
    twitter,
    instagram,
    youtube,
  } = useContext(CampaignContext);
  const disableDrag = useDisableDrag();
  const ctaTrackingUrl = `/campaign/${id}/cta`;

  return (
    <div className="Stream">
      <div className="Stream__content">
        {state === "live" && (
          <>
            <div className="Stream__col Stream__col--video">
              <Video url={videoUrl} />
              <DirectChat imageUrl={chatButtonImageUrl} url={directChatUrl} />
              <Advert assetUrl={advertUrl} link={advertImageUrl} facebook={facebook} twitter={twitter} instagram={instagram} youtube={youtube}/>
            </div>
            {chatUrl && (
              <div className="Stream__col Stream__col--chat">
                <Chat url={chatUrl} />
                {ctaUrl && (
                  <a
                    href={ctaTrackingUrl}
                    target="_blank"
                    className="Stream__cta"
                  >
                    <img src={ctaButtonImageUrl} alt="" />
                  </a>
                )}
              </div>
            )}
          </>
        )}
        {["pre", "post"].includes(state) && (
          <img
            onDragStart={disableDrag}
            src={state === "pre" ? preEventImageUrl : postEventImageUrl}
            alt=""
          />
        )}
      </div>
    </div>
  );
}
