import { useCallback, useEffect, useState } from "react";

export default function useScrollbarWidth() {
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    const size = 100;
    const container = document.createElement("div");
    const child = document.createElement("div");
    container.style.position = "fixed";
    container.style.width = `${size}px`;
    container.style.height = `${size}px`;
    container.style.left = `-${size}px`;
    container.style.top = `-${size}px`;
    container.style.overflow = "auto";
    container.style.backgroundColor = "#000";
    child.style.height = `${size * 2}px`;
    document.body.appendChild(container);
    container.appendChild(child);
    setWidth(container.offsetWidth - container.clientWidth);
    document.body.removeChild(container);
  }, [setWidth]);

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return width;
}
