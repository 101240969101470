import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {AppContext} from '../../config/context';
// @todo put back when temp fix is resolved
import io from "socket.io-client";
import get from "lodash/get";
import "./campaign.scss";
import api from "../../services/api";
import Header from "../../components/header/header";
import Screen from "../../components/screen/screen";
import Stream from "../../components/stream/stream";
import Loading from "../../components/loading/loading";
import CampaignContextProvider from "../../components/campaign-context-provider/campaign-context-provider";
import Poll from "../../components/poll/poll";

const socket = io();

export default function Campaign() {
  const { url } = useParams();
  const {setSiteId, setEventEndAt, setEventId, setPollTemplateIndex} = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);

  const handleCampaignPatch = useCallback(
    ({ id, state, eventEndAt }) => {
      setCampaign((campaign) => ({ ...campaign, id, state, eventEndAt}));
    },
    [setCampaign]
  );

  useEffect(() => {
    socket.on("campaign.patch", handleCampaignPatch);
    return () => socket.off("campaign.patch", handleCampaignPatch);
  }, []);

  useEffect(() => {
    if(!campaign) {return}
    setEventId(campaign.id);
    setSiteId(campaign?.template);
    setEventEndAt(campaign?.eventEndAt ?? null);
    setPollTemplateIndex(campaign?.pollTemplateIndex ?? 1);
  }, [campaign, setEventId, setSiteId, setEventEndAt])

  useEffect(() => {
    setLoading(true);
    api
      .campaign(url)
      .then(({ data = null }) => setCampaign(data))
      // @todo handle errors
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [url]);

  return (
    <Screen
      name={`campaign ${get(campaign, "template")}`}
      style={{
        backgroundImage:
          get(campaign, "state", "") !== "live"
            ? undefined
            : `url(${get(campaign, "liveEventImageUrl")})`,
      }}
    >
      {loading && <Loading />}
      {!loading && !campaign && <h1>Campaign Not Found</h1>}
      {!loading && campaign && (
        <CampaignContextProvider campaign={campaign}>
          <Header />
          <Stream />
        </CampaignContextProvider>
      )}
    </Screen>
  );
}
