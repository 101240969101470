import React from "react";
import { CampaignContext } from "../../config/context";

export default function CampaignContextProvider({ children, campaign = {} }) {
  return (
    <CampaignContext.Provider value={{ ...campaign }}>
      {children}
    </CampaignContext.Provider>
  );
}
