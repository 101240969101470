import React from "react";
import "./advert.scss";
import facebookIcon from "../../assets/fb.png";
import twitterIcon from "../../assets/twitter.png";
import instagramIcon from "../../assets/insta.png";
import youtubeIcon from "../../assets/yt.png";

export default function Advert({
  assetUrl,
  link,
  facebook,
  twitter,
  instagram,
  youtube,
}) {
  if (!assetUrl) {
    return null;
  }

  const type = assetUrl.endsWith("mp4") ? "video" : "image";

  const hasSocials =
    facebook !== null ||
    twitter !== null ||
    instagram !== null ||
    youtube !== null;

  return (
    <div className="Asset Advert">
      {type === "image" && <img src={assetUrl} alt="" />}
      {type === "video" && <video muted autoPlay loop src={assetUrl} />}
      <a href={link} className="Advert__link" target="_blank"></a>
      {hasSocials && (
        <div className="Advert__socials">
          {facebook !== null && (
            <a href={facebook} target="_blank">
              <img src={facebookIcon} alt="" />
            </a>
          )}
          {twitter !== null && (
            <a href={twitter} target="_blank">
              <img src={twitterIcon} alt="" />
            </a>
          )}
          {instagram !== null && (
            <a href={instagram} target="_blank">
              <img src={instagramIcon} alt="" />
            </a>
          )}
          {youtube !== null && (
            <a href={youtube} target="_blank">
              <img src={youtubeIcon} alt="" />
            </a>
          )}
        </div>
      )}
    </div>
  );
}
