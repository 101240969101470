import React from "react";
import "./video.scss";

export default function Video({ url }) {
  return (
    <div className="Video">
      <iframe src={url} allow="autoplay" allowFullScreen />
    </div>
  );
}
